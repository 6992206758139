@import url('https://fonts.googleapis.com/css2?family=Krona+One&family=Krub:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

.blogs-container {
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    justify-content: center;
}

.each-blog-container {
    width: 350px;
    height: 460px;
    text-decoration: none;
    margin: 20px;
    color: #799a63;
    font-family: "Krub", sans-serif;
    box-shadow: 0 10px 10px 5px rgba(100, 100, 100, .2);
    overflow-y: auto;
    scrollbar-width: none;
}

.blog-image {
    width: 100%;
    height: 280px;
    object-fit: cover;
}

.each-blog-container-title {
    font-family: "Krona One", sans-serif;
    font-size: 21px;
    padding: 10px 0px;
}

.title-underline {
    border: 2px solid #799a63;
    width: 100px;
    border-radius: 10px;
}

.each-blog-container-description {
    padding: 10px 20px 5px;
    height: 140px;
    word-break: break-all;
    overflow-wrap: break-word;
}

.add-blog {
    width: 100%;
    height: max-content;
    padding: 2rem 3rem;
    display: flex;
    justify-content: end;
    align-items: center;
}

.add-blog-button {
    color: #13A014;
    background-color: #f1f1ff;
    padding: .7rem 1.5rem;
    border-radius: .6rem;
    text-decoration: none;
    font-size: 15px;
    font-weight: bold;
    border: 1px solid #13A014;
}

.add-blog-button:hover {
    background-color: #13A014;
    color: #fff;
    transition: .5s;
}

.blog-modal {
    width: 800px;
    display: flex;
    align-items: center;
    margin: 0px auto;
}

.each-blog-delete-button:hover {
    background-color: #000;
    color: white;
    transition: .5s;
}

.each-blog {
    width: 800px;
    background-color: white;
    max-height: 82vh;
    min-height: 40vh;
    overflow-y: auto;
    scrollbar-width: none;
}

.each-blog-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.each-blog-description {
    width: 90%;
    color: #799a63;
    padding: 10px 30px 0px;
    word-break: break-all;
    overflow-wrap: break-word;
}

.each-blog-title {
    font-family: "Krona One", sans-serif;
    font-size: 21px;
    padding: 0px 0px 10px;
}

.title-underline {
    border: 2px solid #799a63;
    width: 100px;
    border-radius: 10px;
    margin: 0px 0px 10px;
}

.edit-input {
    width: 750px;
}

.edit-button {
    margin: 20px auto;
    display: flex;
    width: 300px;
    justify-content: center;
}


@media(max-width: 1170px) {
    .blogs-container {
        grid-template-columns: auto auto;
    }
}

@media(max-width: 777px) {
    .each-blog-container {
        width: 300px;
    }
}

@media(max-width: 830px) {

    .blog-modal,
    .each-blog {
        width: 650px;
    }

    .edit-input {
        width: 600px;
    }
}

@media(max-width: 680px) {
    .blogs-container {
        grid-template-columns: auto;
    }

    .blog-modal,
    .each-blog {
        width: 450px;
    }

    .edit-input {
        width: 400px;
    }
}

@media(max-width: 520px) {
    .blogs-container {
        grid-template-columns: auto;
    }

    .each-blog-container {
        width: 300px;
        height: 350px;
    }

    .blog-image {
        height: 180px;
    }

    .each-blog-container-description {
        height: 130px;
    }
}

@media(max-width: 500px) {

    .each-blog,
    .blog-modal {
        width: 380px;
    }

    .edit-input {
        width: 300px;
    }

    .edit-button {
        margin-left: 20px;
    }
}