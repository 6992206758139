.form-container{
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.save_btn {
    background-color: #13A014;
    padding: .2rem .6rem;
    margin: 1rem;
    border-radius: 5px;
    border: 1px solid #fff;
    color: #fff;
    width: 90%;
}
@media(max-width: 770px){
    .form-container{
        width: 130%;
   }
}
@media(max-width: 685px){
    .form-container{
        display: flex;
        flex-direction: column;
        width: 100%;
   }
}